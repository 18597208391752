@font-face {
	font-family: 'opal';
	src: url('./fonts/Opal.ttf');
}
@font-face {
  font-family: 'FontAwesome';
  src: url('./fonts/fontawesome-webfont.eot?v=4.7.0');
  src: url('./fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'), url('./fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('./fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('./fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), url('./fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
* {
  box-sizing: border-box;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-circle-o:before {
  content: "\f10c";
}
.fa-circle:before {
  content: "\f111";
}
.fa-industry:before {
  content: "\f275";
}
.fa-desktop:before {
  content: "\f108";
}

.fa-user:before {
  content: "\f007";
}

.fa-building-o:before {
  content: "\f0f7";
}
.fa-hospital-o:before {
  content: "\f0f8";
}

.fa-building:before {
  content: "\f1ad";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-facebook:before {
  content: "\f09a";
}

.fa-twitter:before {
  content: "\f099";
}
.fa-volume-control-phone:before {
  content: "\f2a0";
}

.fa-whatsapp:before {
  content: "\f232";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-linkedin:before {
  content: "\f0e1";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-envelope:before {
  content: "\f0e0";
}

.fa-quote-left:before {
  content: "\f10d";
}
.fa-bars:before {
  content: "\f0c9";
}
ul {
  list-style: none;
}
nav ul li{
  display: inline-block;
  padding: 10px;
}
ul li a{
  text-decoration: none;
  color: #659a41;
  font-size: 18px;
}
.is-active, ul li a:active, ul li a:hover{
  font-weight: bold;
  color: black;
}
.menu-icon {
  margin-left: 20px;
  font-size: 30px;
  color: #659a41;
  float: right;
  display: none;
}
.App {
  text-align: center;
}

.App-logo {
  height: 12vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #7bfb61;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1, h2, h3, h4, h5, h6 {
	color: #659a41;
	font-weight: bold;
	font-family: 'opal';
	text-transform: capitalize;
}
h1 {
  margin: 15px 5px 15px;
	font-size:64px;
}
h2 {
  color: rgb(255, 255, 255);
  font-size: 30px;
}


label {
	color: #659a41;
	display: block;
	font-size: 0.21em;
	letter-spacing: 1px;
	margin-top: -34px;
}
label span.group {
	color: #659a41;
	font-size: 1.3em;
}

.App-header h1 label i {
  font-size: 1em;
}

footer {
  background-color: black;
  color: white;
  min-height: 10vmin;
  padding: 15px;
}

@media only screen and (max-width: 845px) {
  .menu-icon {
    display: block;
  }
  .navigation-menu ul{
    /* previous styles */
    display: none;
  }
  .navigation-menu-expanded ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    background-color: black;
    z-index: 7;
    height: 100vmax;
    margin: 0;
  }
  .navigation-menu-expanded ul li{
    color: white;
    background-color: black;
    width: 100%;
    text-align: center;
  }
  .is-active,ul li a:hover {
    color: white;
  }
}
