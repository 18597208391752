.banner
    background-size: cover
    background-color: rgba(12, 11, 11, 0.694)
    margin: 0
    padding: 25px 0
    h2
        padding: 25px
        font-size: 50px
    p
        margin: -80px 0 60px 0
        color: #aaa
        letter-spacing: 5px
        font-size: 16px

.breadcum
    background-size: cover
    background-color: rgba(238, 238, 238, 1)
    text-align: left
    padding: 10px 0 10px 45px
    margin: 0
    color: #659a41
    b
        color: black
    
.content
    padding: 15px
    display: flex
    flex-direction: column
    justify-items: center
    .leftPart
        width: 30%
        padding: 15px
        text-align: left
        background-color: black
        color: white
        .title
            font-size: 25px
            margin: 15px
        .grid-contact-info
            display: grid
            grid-template-columns: 80px 1fr
            span
                margin: 15px
                grid-row: 1 / 2 span
                i
                    width: 50px
                    height: 50px
                    border: 2px solid #eee
                    text-align: center
                    line-height: 50px
                    font-size: 25px
            h5
                font-size: 16px
                padding: 0
                margin: 15px
                span
                    display: block
                    padding: 0
                    margin: 0
                    font-weight: normal
                    font-size: 14px
                    color: white
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
        .social-icons
            ul
                li
                    display: inline-block
                    font-size: 25px
                    padding: 15px

    .rightPart
        width: 70%
        .map
            min-height: 15vmin
            min-width: 100%
    
@media only screen and (max-width:1089px) 
    .content
        display: block
        .leftPart
            width: 100%
        .rightPart
            width: 100%
