.legend
    h2
        margin: 0
        font-size: 48px
        span
            font-weight: normal
    p
        margin: 0
        font-size: 20px
        letter-spacing: 10px
.content-home
    max-width: 100%
    margin: 0 15px
    display: grid
    gap: 15px
    align-items: center
    h3
        font-size: 50px
        margin-bottom: 10px
        span
            font-size: 35px
            text-transform: none
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif

    p 
        text-align: justify
        margin: 0 20px
    
    
    .twopart
        padding: 15px
        text-align: left
        &:nth-child(4)
            grid-column: 2
            grid-row: 3 / span 3         
        .img
            display: block
            width: 80vmin
            height: 80vmin
            padding: 15px
            border: 1px double #eee
        h4
            font-size: 25px
            margin: 5px 15px
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
            text-align: left
            color: black
        h5
            font-size: 20px
            margin: 10px 0 0
        h6
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
            font-size: 18px
            margin: 10px 0 0
        p
            text-align: justify
        ul 
            margin-top: 3em
            li 
                text-align: left
                margin: 15px 0
                color: #444
                font-weight: 600
                font-size: 1.12em
                span
                    padding: 12px
                    color: #23B684
                    background: #fff
                    text-align: center
                    line-height: 20px
                    margin-right: 20px
                    font-size: 1.4em
                    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1)
                    -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1)
                    -moz-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1)
                    -o-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1)
                    border-radius: 50%
                    -webkit-border-radius: 50%
                    -o-border-radius: 50%
                    -moz-border-radius: 50%
.down-banner
    background-color: black
    background-size: cover
    padding: 15px
    border: 2px double #333
    h4
        font-size: 45px
.content
    padding: 0 15px

articles
    text-align: left
    margin: 0
    display: grid
    grid-gap: 30px
    grid-template-columns: 47% 24% 24%
    .three-forth
        padding: 20px
        border: 2px double #333
        grid-column: 1/ span 2

    .one-forth
        padding: 15px
        border: 2px double #333
        grid-row: 2 / span 7
        grid-column: 3
        .stick
            position: sticky
            top: 0
    h3
        text-align: center
        font-size: 55px
        grid-column: 1 /span 3
    .article-left
        grid-column: 1 /span 2
    article
        h3
            text-align: left
            font-size: 20px
            margin: 0
        h4
            margin: 0
        p 
            margin: 0
            padding: 10px 0
            text-align: justify
    .mission
        padding: 15px 25px
        background-color: black
        color: white
        h3
            font-size: 25px
            text-transform: uppercase
            margin: 0
            letter-spacing: 8px
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
        p
            margin-top: 0
            color: #aaa
            i
                font-size: 35px
                margin-right: 10px
.clientList
    display: grid
    grid-template-columns: repeat(3 , auto)
    grid-row-gap: 30px
    grid-column-gap: 15px 
    .cards
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
        width: 60vmin
        margin: auto
        text-align: center
        display: flex
        flex-direction: column
        .card-header
            background: #333
            height: 25vmin
            img
                max-width: 60vmin
                height: 25vmin
        .card-body
            padding: 15px
            h3
                font-weight: bold
        .card-footer
            background: black
            color: #aaa
            padding: 15px 0 0 0
            height: 10vmin

            

@media only screen and (max-width:1089px) 
    .content-home 
        h3
            grid-column: 1
        p
            grid-column: 1
        .twopart 
            grid-column: 1
            &:nth-child(4)
                grid-column: 1
                grid-row: 4
    articles
        grid-template-columns: auto
        h3
            grid-column: 1
        .article-left
            grid-column: 1
        .three-forth
            grid-column: 1
        .one-forth
            grid-column: 1
            grid-row: 6
